import { DomainInfo } from '_backend/integration/domain-info';

import { AlternateLink, AlternateLinksProps } from './types';

export const getDomain = (origin: string, url: string) => {
  if (origin.includes('sandbox')) {
    const sandboxIso31661 = url === 'pr' ? '' : `${url}-`;
    const sandboxDomain = origin.replace('https://', '');
    const fullSandboxUrl = `https://${sandboxIso31661}${sandboxDomain}`;

    return DomainInfo.getFromOrigin(fullSandboxUrl);
  }

  return DomainInfo.getFromOrigin(url);
};

const getDefaultLinks = (origin: string, query?: string) => {
  const links: AlternateLink[] = [];

  Object.keys(DomainInfo.getDomainInfoObject(origin)).map((url) => {
    const domain = getDomain(origin, url);

    if (domain) {
      const locales = domain.getLocales();
      const iso31661 = domain.getIso31661();
      const mainLocale = domain.getMainLocale();
      return locales?.map((locale) => {
        links.push({
          hreflang: iso31661 ? `${locale}-${iso31661.toLowerCase()}` : locale,
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          href: `${url}${mainLocale === locale ? '' : `/${locale}`}${query || ''}`,
          key: `${url}-${locale}${query || ''}`,
        });
      });
    }
  });

  return links;
};

export const getAgencyAlternates = (agencySlug: string, origin: string, pageLocale: string): AlternateLink[] => {
  const links: AlternateLink[] = [];

  Object.keys(DomainInfo.getDomainInfoObject(origin)).map((url) => {
    const domain = getDomain(origin, url);

    if (domain) {
      const locales = domain.getLocales();
      const iso31661 = domain.getIso31661();
      const mainLocale = domain.getMainLocale();
      return locales?.map((locale) => {
        if (url === origin && locale === pageLocale) return;
        links.push({
          hreflang: iso31661 ? `${locale}-${iso31661.toLowerCase()}` : locale,
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          href: `${url}${mainLocale === locale ? '' : `/${locale}`}/agency/${agencySlug}`,
          key: `${url}-${locale}/agency/${agencySlug}`,
        });
      });
    }
  });
  return links;
};

export const getLinks = (props: AlternateLinksProps): AlternateLink[] => {
  const { locale, canonical, query = '', alternateLinks: databaseLinks, origin, iso31661 } = props;

  // Coming directly from Public-API
  const links = databaseLinks
    ? databaseLinks.map(({ url, metadata }) => ({
        hreflang: metadata?.hreflang ?? '',
        href: `${url}${query}`,
        key: `${url}${query}`,
      }))
    : [];

  // Default for the current page with the current URL
  const defaultKey = `${canonical}${query}`;
  const selfIsPresent = links.some(({ key }) => key === defaultKey);
  if (!selfIsPresent) {
    links.push({
      hreflang: iso31661 ? `${locale}-${iso31661.toLowerCase()}` : locale,
      href: `${canonical}${query}`,
      key: `${canonical}${query}`,
    });
  }

  // Only 2 type of pages have alternate link: longtails and homepages.
  // Homepages have no alternate links coming from Public-API so we
  // just generate the defaults from the env files.
  return databaseLinks ? links : getDefaultLinks(origin, query);
};
