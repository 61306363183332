import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const Headline = styled.h1`
  font-size: 20rem;
  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    font-size: 30vw;
  }
`;

export const LogoMonogram = styled.img`
  height: 4rem;
`;
