import { isNonEmptyArray } from '@sortlist-frontend/utils';

import { AlternateLinksProps } from './types';
import { getLinks } from './utils';

export const AlternateLinks = (props: AlternateLinksProps) => {
  const links = getLinks(props);

  return (
    <>
      {isNonEmptyArray(links) &&
        links.map(({ key, href, hreflang }) => {
          return <link key={key} rel="alternate" href={href} hrefLang={hreflang} />;
        })}
    </>
  );
};
