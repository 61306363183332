import { Logo } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { LayoutPagesRouter } from '_components/layout/LayoutPagesRouter';
import { PublicAppContextProvider } from '_core/context/public-app-context';

import * as S from './public-error.styles';

type Props = {
  message?: string;
  locale?: string;
};

export const Public404Page: React.FC<Props> = ({ message, locale = 'en' }) => {
  const { t } = useTranslation(['common', 'agency']);

  return (
    <PublicAppContextProvider canonical="" origin="" locale={locale}>
      <LayoutPagesRouter nav={false}>
        <div
          className="layout-fill flex layout-column bg-secondary-900 layout-align-start-stretch"
          data-testid="public-404">
          <div className="flex layout-column p-16 layout-align-center-center">
            <Logo type="monogram" inversion="positive" height={64} />
            <S.Headline className="bold serif text-secondary-100 mb-64 lh-1">404</S.Headline>
            <h2 className="h5 bold mb-8 text-secondary-100">{t('common:pageErrors.sorry')}</h2>
            <p className="p text-secondary-300">{message}</p>
            <a className="text-underline text-secondary-100 mt-32" href={`/`}>
              Back to home
            </a>
          </div>
        </div>
      </LayoutPagesRouter>
    </PublicAppContextProvider>
  );
};
