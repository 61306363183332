import { useMediaQuery } from '@sortlist-frontend/design-system';
import { isBrowser } from '@sortlist-frontend/utils';
import { Fragment, ReactNode, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { APPS } from './analytics';
import { useTracker } from './useTracker';

type User = {
  id: string;
  userId: number;
  email: string;
  firstName: string;
  calendarUrl?: string | null;
  intercomUserHash?: string | null;
  lastName: string;
  locale: string;
  oauthId: string;
  phone: string | null;
  pictureOriginal?: string | null;
  sortlistAdminMetadatum?: { id: string } | null;
  activityFeedToken?: string;
  appToken?: string | null;
  agencies?: { id: string; uuid: string; logo: string | null; slug: string; name: string }[];
};

// Segment settings
// @link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#selecting-destinations
// @link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
type TrackingProviderProps = {
  /** App */
  app: APPS;
  /** Name to send to segment */
  name?: string;
  /** Category to send to segment */
  category?: string;
  /** Properties send to segment */
  properties?: Record<string, unknown>;
  /** Shortcut for {Integrations: {Intercom: { hideDefaultLauncher: true }}} */
  hideIntercom?: boolean;
  /** If undefined, Hotjar param will be discarded */
  enableHotJar?: boolean;
  /** Disable tracking upon this condition to be true */
  disableTracking?: boolean;
  /** User to be used on identify */
  user?: User;
  /** User anonymous id to identify */
  anonymousId?: string;

  children: ReactNode;
};

const defaultProps: Partial<TrackingProviderProps> = {
  hideIntercom: false,
  enableHotJar: undefined,
  disableTracking: false,
};

export const TrackingProvider: React.FC<TrackingProviderProps> = (props) => {
  const { children, hideIntercom, enableHotJar, disableTracking, app } = { ...defaultProps, ...props };
  const trackerProps = { ...(props.properties ?? {}) };
  const { name, category, user, anonymousId } = props;
  const { page, identify, track, ready } = useTracker({ app });

  const { media } = useMediaQuery();
  const hideIntercomLauncher = hideIntercom ?? media.xs;

  useDeepCompareEffect(() => {
    if (isBrowser() && !disableTracking) {
      let options: Record<string, Record<string, unknown>> = {
        integrations: {
          ...(hideIntercomLauncher
            ? {
                Intercom: {
                  hideDefaultLauncher: hideIntercomLauncher,
                },
              }
            : {}),
          ...(typeof enableHotJar === 'boolean' ? { Hotjar: enableHotJar } : {}),
        },
      };

      if (Object.keys(options.integrations).length === 0) {
        options = {};
      }

      if (typeof category === 'string' && typeof name === 'string') {
        page({ category, name, ...trackerProps }, options);
      } else if (typeof name === 'string') {
        page({ name, ...trackerProps }, options);
      } else {
        page(trackerProps, options);
      }
    }
  }, [name, category, trackerProps, disableTracking]);

  useEffect(() => {
    if (ready && !disableTracking && user) {
      identify(
        user.userId.toString(),
        {
          user_id: user.userId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          locale: user.locale,
          user_hash: user.intercomUserHash,
        },
        {
          Intercom: {
            user_id: user.userId,
            user_hash: user.intercomUserHash,
            hideDefaultLauncher: hideIntercomLauncher,
          },
        },
      );
      track('signedIn', {});
    }
    if (app === 'appPublic' && anonymousId != null) {
      identify(anonymousId, {});
    }
  }, [user, ready]);

  return <Fragment>{children}</Fragment>;
};
