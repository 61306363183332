import { FC, ReactNode } from 'react';

import { analytics } from './analytics';

type AnalyticsProviderProps = {
  children: ReactNode;
  segmentOptions?: {
    // Used for page tracking
    page?: {
      category: string;
      name: string;
      properties?: Record<string, unknown>;
    };
    // Send here if you want to overwrite the default, for example: { Intercom: { hideDefaultLauncher: false } }
    integrations?: Record<string, boolean>;
    // If the user agreed with allowing us to use our integrations
    userConsent?: string | null;
    // Properties sent to segment
    excludedIntegrations?: string[];
  };
};

export const AnalyticsProvider: FC<AnalyticsProviderProps> = (props) => {
  const { children, segmentOptions } = props;

  analytics(
    {
      All: false,
      // TODO: -> this will be used when we have the user consent implemented
      // ...(segmentOptions?.userConsent ? enableDisabledIntegrations(DISABLED_INTEGRATIONS) : DISABLED_INTEGRATIONS),
      ...(segmentOptions?.integrations || {}),
    },
    segmentOptions?.excludedIntegrations,
  );

  return <>{children}</>;
};
