import { Button, type Color, type Size, type Variant } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { ReactElement } from 'react';

import { briefingConfig } from '_features/briefing/briefing.config';
import { BriefingScreenContent } from '_types/generic';

import { CTA, OPEN_BRIEFING, useBriefingMessage } from './BriefingDialog';

export type BriefingButtonProps = {
  page?: string;
  cta?: string;
  truncate?: boolean;
  size?: Size;
  buttonStyle?: Color;
  buttonVariant?: Variant;
  className?: string;
  label?: string | ReactElement;
  opened?: boolean;
  isReferral?: boolean;
  dataTestid?: string;
  initialModalContent?: BriefingScreenContent;
};

export const BriefingButton: React.FC<BriefingButtonProps> = (props) => {
  const { size, buttonStyle, buttonVariant, className, label, dataTestid, cta, ...restProps } = props;

  const { t } = useTranslation(briefingConfig.i18nNamespaces);
  const { sendMessage } = useBriefingMessage();

  return (
    <Button
      data-testid={dataTestid || 'briefing-button'}
      size={size || 'sm'}
      buttonStyle={buttonStyle || 'primary'}
      buttonVariant={buttonVariant || 'raised'}
      label={label || t('briefing:buttonLabel')}
      onClick={() => {
        sendMessage(OPEN_BRIEFING, { cta: cta as CTA });
      }}
      className={className}
      {...restProps}
    />
  );
};
