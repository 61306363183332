import { Star } from '@mui/icons-material';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

export type RatingProps = {
  activeColorClass?: string;
  inactiveColorClass?: string;
  direction?: 'row' | 'column';
  rate: number;
  /** Base count */
  count?: 1 | 5 | 10 | 100;
  children?: ReactNode;
  className?: string;
  size?: number;
  ['data-testid']?: string;
};

/*
 * TEMPORARY:HACK: adding ({...}: Props) as a temporary fix for
 * https://github.com/storybookjs/storybook/blob/2d540b988ee665f793d0ef9860b732087c817193/MIGRATION.md#reactfc-interfaces
 * until the issue is resolved
 */

const getNewRate = (count: number, rate: number): number => {
  if (typeof rate === 'number') {
    switch (count) {
      case 1:
        return rate * 5;
      case 10:
        return rate / 2;
      case 100:
        return rate / 20;
      default:
        return rate;
    }
  } else {
    throw new Error(`Rate must be a number not a ${typeof rate}`);
  }
};

const defaultProps = {
  rate: 0,
  direction: 'row',
  activeColorClass: 'text-warning-500',
  inactiveColorClass: 'text-secondary-300',
  size: 24,
};

export const Rating: FC<RatingProps> = (props: RatingProps) => {
  const {
    count = 5,
    className,
    direction,
    children,
    size,
    rate,
    inactiveColorClass,
    activeColorClass,
  } = { ...defaultProps, ...props };

  const newRate = getNewRate(count, rate);
  const plainStars = Math.floor(newRate);
  const isInteger = Number.isInteger(newRate);
  const halfStar = isInteger ? !Number.isInteger(Math.trunc(newRate)) : !Number.isInteger(newRate);
  const emptyStars = Math.floor(5 - newRate);
  const halfStarSize = ((size || 24) / 100) * ((newRate % 1) * 100);

  if (rate > count) return <p>Rating error</p>;

  return (
    <div
      data-testid={props['data-testid'] ?? `star-rating-${rate}`}
      className={clsx(className, direction && `layout-${direction}`, 'layout-align-start-center')}>
      <div className="layout-row layout-align-center-center">
        {Array.from(Array(plainStars), (number, index) => (
          <Star key={index} style={{ fontSize: size }} className={clsx(activeColorClass)} />
        ))}

        {halfStar && (
          <div className="layout-column layout-align-center-center relative">
            <Star
              style={{
                top: 0,
                left: 0,
                zIndex: 1,
                fontSize: size,
                clipPath: `inset(0 0 0 ${halfStarSize}px)`,
              }}
              className={clsx(inactiveColorClass, 'absolute')}
            />
            <Star className={clsx(activeColorClass)} style={{ fontSize: size }} />
          </div>
        )}

        {Array.from(Array(emptyStars), (number, index) => (
          <Star key={index} style={{ fontSize: size }} className={clsx(inactiveColorClass)} />
        ))}
      </div>
      {children}
    </div>
  );
};
