import { HttpException, isPlainObject, serializeHttpException } from '@sortlist-frontend/utils';

import { QueryException } from '_backend/core/cqs-query/exception';
import { serializeQueryException } from '_core/error/query/query-exception-serializer';

export type SerializedError = {
  __type: 'SerializedError';
  status: number | null;
  errorClass: string | null;
  message: string | null;
  url: string | null;
  method: string | null;
  stackTrace: string | null;
  previousErrorMessage: string | null;
};

export const isSerializedError = (val: unknown): val is SerializedError => {
  return isPlainObject(val) && val['__type'] === 'SerializedError';
};

export const serializeError = (e: Error | HttpException | QueryException): SerializedError => {
  if (e instanceof HttpException) {
    return serializeHttpException(e);
  }
  if (e instanceof QueryException) {
    return serializeQueryException(e);
  }
  const { message = null, stack } = e;
  return {
    __type: 'SerializedError',
    message,
    errorClass: e.constructor?.name ?? 'Error',
    status: null,
    url: null,
    method: null,
    stackTrace: stack ?? null,
    previousErrorMessage: null,
  };
};
