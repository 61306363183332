/**
 * @todo ensure database credentials never end-ups in the
 *       query message (with prisma it shouldn't be the case) but
 *       a good practice is to filter out sensitive data
 */
export type QueryExceptionProps = {
  message?: string;
  httpStatus?: number;
  previousError?: Error | null;
};

export class QueryException extends Error {
  public readonly props: QueryExceptionProps;
  public readonly stackTrace: string | null;
  private _previousError!: Error | null;

  get previousError(): Error | null {
    return this._previousError ?? null;
  }

  set previousError(previousError: Error | null) {
    this._previousError = previousError;
    if (previousError !== null) {
      this.message = `${this.message}, innerException: ${
        previousError.message.length > 0 ? previousError.message : previousError.name
      }`.trim();
    }
  }

  constructor(props: QueryExceptionProps) {
    const { message, previousError = null } = props;
    super(message);
    this.props = props;
    Object.setPrototypeOf(this, QueryException.prototype);
    this.name = QueryException.prototype.constructor.name;
    this.stackTrace = previousError?.stack ?? null;
    this.previousError = previousError ?? null;
  }

  getName(): string {
    return this.name;
  }

  getHttpStatus(): number | undefined {
    return this.props.httpStatus;
  }

  toString(): string {
    return `${this.name}: ${this.message.trim()}`;
  }
}
